import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import {
  getContestRequest,
  sendContestNotifications
} from 'src/redux/actions/contests';
import TinyMCE from 'src/components/TinyMCE';
import { MenuItem, Select, TextField } from '@mui/material';
import { productTypes } from 'src/constants';

const ContestNotifications = () => {
  const client = useSelector(state => state.clientStore?.client);
  const contest = useSelector(state => state.contestStore?.contest);
  const contestFetching = useSelector(state => state.loadingStore.GET_CONTEST);
  const submitted = useSelector(state => state.completedStore.SEND_CONTEST_NOTIFICATIONS);
  const [notificationOption, setNotificationOption] = useState();
  const [subject, setSubject] = useState();
  const [touched, setTouched] = useState(false);
  const { contestURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!contestURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!contest) {
      dispatch(getContestRequest({ clientURL: client.url, contestURL }));
    }
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'contestNotifications', client, contest }));
    dispatch(updateDrawerContent({
      fetching: contestFetching,
      key: 'contestNotifications',
      options: { client, contest } 
    }));

    if (contest?.type === productTypes.contest) {
      setNotificationOption('entrants');
    }
    if (contest?.type === productTypes.scholarship) {
      setNotificationOption('judges');
    }
  }, [contestFetching]);

  useEffect(() => {
    if (submitted) {
      setSubject();
      setTouched(false);
    }
  }, [submitted]);

  const notificationOptions = [{
    label: 'All Entrants',
    value: 'entrants'
  }, {
    label: 'Entrants With Incomplete Entries',
    value: 'incomplete-entries'
  }, {
    label: 'Entrants With Disqualified Entries',
    value: 'disqualified-entries'
  }, {
    label: 'All Judges',
    value: 'judges'
  }, {
    label: 'Judges With Incomplete Scores',
    value: 'incomplete-scores'
  }, {
    label: 'All Winners',
    value: 'winners'
  }];

  const scholarshipNotificationOptions = [{
    label: 'All Judges',
    value: 'judges'
  }, {
    label: 'Judges With Incomplete Scores',
    value: 'incomplete-scores'
  }];

  const handleNotificationTypeChange = (value) => {
    setNotificationOption(value);
  }

  const handleSubjectChange = (value) => {
    setSubject(value);
  }

  const handleTouched = () => {
    setTouched(true);
  };

  const handleSubmit = (event) => {
    dispatch(sendContestNotifications({ clientURL: client.url, contestURL: contest.url, form: {
      mailContent: {
        notificationText: event,
        notifySubject: subject
      },
      notificationOption
    } }));
  }

  return (
    notificationOption && (
      <TinyMCE
        clearOnSave
        CTA="Send"
        disabled={!subject}
        handleSubmit={(event => handleSubmit(event))}
        label="Email Message:"
        optionalFormData={() => 
          <div className="box-content optional-form-data">
            <div className="optional-form-data-select">
              <label>Send Email Notification To</label>
              <Select
                onChange={e => {handleNotificationTypeChange(e.target.value)}}
                value={notificationOption}
              >
                {
                  ((contest?.type === productTypes.contest ? notificationOptions : scholarshipNotificationOptions) || []).map(option => {
                    return (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >{option.label}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>
            <div className="optional-form-data-subject">
              <label>Email Subject:</label>
              <TextField
                autoComplete="off"
                error={!!(touched && !subject)}
                helperText={touched && !subject ? 'Subject Required' : null}
                onChange={e => {handleSubjectChange(e.target.value)}}
                onFocus={handleTouched}
                required
                type="text"
                value={subject || ''}
              />
            </div>
          </div>
        }
        requestPrefix="SEND_CONTEST_NOTIFICATIONS"
        required
        title="Send Email Notifications"
        value=""
      />
    )
  )
}

export default ContestNotifications;
