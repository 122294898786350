import * as actions from 'src/redux/actions/app';
import { toast } from 'react-toastify';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CHANGE_EMAIL_SUCCESS:
      toast.success('Email successfully changed.');
      return state;

    case actions.CHANGE_PASSWORD_SUCCESS:
      toast.success('Password successfully changed.');
      return state;

    case actions.CLEAR_ERROR_LOGS_SUCCESS:
      return { ...state, errorLogs: [] };

    case actions.GET_CURRENT_RESOURCES_SUCCESS:
      return { ...state, resources: { contests: action.context.contests, events: action.context.events }};

    case actions.GET_ERROR_LOGS_REQUEST:
      return { ...state, errorLogs: [] };

    case actions.GET_ERROR_LOGS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      return action.context?.errorLogs ? { ...state, errorLogs: action.context.errorLogs } : state;

    case actions.GET_INVOICES_REQUEST:
      return { ...state, invoices: [], invoiceItems: [] };

    case actions.GET_INVOICES_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      return action.context?.invoices ? { ...state, invoices: action.context.invoices, invoiceItems: action.context.invoiceItems } : state;

    case actions.GET_PASSWORD_REQUESTS_REQUEST:
      return { ...state, passwordRequests: [] };

    case actions.GET_PASSWORD_REQUESTS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      return action.context?.passwordRequests ? { ...state, passwordRequests: action.context.passwordRequests } : state;

    case actions.GET_SYSTEM_NOTIFICATIONS_REQUEST:
      return { ...state, systemNotifications: [] };

    case actions.GET_SYSTEM_NOTIFICATIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      return action.context?.systemNotifications ? { ...state, systemNotifications: action.context.systemNotifications } : state;

    case actions.MIGRATE_USER_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('User migration complete.');

      return state;

    case actions.REMOVE_INVOICE_SUCCESS:
      toast.success('Invoice removed.');
      return state;

    case actions.REMOVE_INVOICE_LINE_ITEM_SUCCESS:
      if (action.context.itemID) {
        toast.success('Invoice line item removed.');
        const updatedLineItems = state.invoiceItems.filter(item => item.itemID !== action.context.itemID);
        return { ...state, invoiceItems: updatedLineItems };
      }

      return state;

    case actions.REMOVE_SYSTEM_NOTIFICATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      if (action.context?.notificationID) {
        toast.success('System notification removed.');
        const updatedSystemNotifications = state.systemNotifications.filter(systemNotification => {
          return systemNotification.notificationID !== action.context.notificationID;
        });
        return { ...state, systemNotifications: updatedSystemNotifications };
      }

      return state;

    case actions.SEND_NOTIFICATIONS_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }

      toast.success('Notifications have been sent.');

      return state;

    case actions.UPDATE_INVOICE_SUCCESS:
      toast.success('Invoice updated.');
      return state;

    case actions.UPDATE_SYSTEM_NOTIFICATION_SUCCESS:
      if (action.context?.error) {
        toast.error(action.context?.error);
        return state;
      }
      
      if (action.context?.systemNotification) {
        let updatedSystemNotifications = [ ...(state.systemNotifications || []) ];
        const index = updatedSystemNotifications.findIndex(systemNotification => systemNotification.notificationID === action.context.systemNotification.notificationID);
        if (index !== -1) {
          updatedSystemNotifications[index] = {...updatedSystemNotifications[index], ...action.context.systemNotification};
          toast.success('System notification updated.');
        } else {
          updatedSystemNotifications = [...updatedSystemNotifications, action.context.systemNotification];
          toast.success('System notification inserted.');
        }
        return { ...state, systemNotifications: updatedSystemNotifications };
      }
      return state;

    default:
      return state;
  }
}

export default reducer;
