import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventRequest,
  reorderEventSponsorTiersRequest,
  updateEventSponsorOptionsRequest
} from 'src/redux/actions/events';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { useNavigate, useParams } from 'react-router-dom';
import { openDialog } from 'src/redux/actions/dialog';
import { openModal } from 'src/redux/actions/modal';
import { fieldTypes, sponsorPlacements, sponsorSizes, yesOrNo } from 'src/constants';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import ResourceList from 'src/components/ResourceList';
import AutoForm from 'src/components/AutoForm';

const EventSponsors = () => {
  const client = useSelector(state => state.clientStore?.client);
  const event = useSelector(state => state.eventStore?.event);
  const eventFetching = useSelector(state => state.loadingStore.GET_EVENT);
  const updatingSponsorSettings = useSelector(state => state.loadingStore.UPDATE_EVENT_SPONSOR_SETTINGS);
  const updatingSponsorTiers = useSelector(state => state.loadingStore.REORDER_EVENT_SPONSOR_TIERS);

  const { eventURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!eventURL) {
    navigate('/');
  }

  useEffect(() => {
    if (!event) {
      dispatch(getEventRequest({ clientURL: client.url, eventURL }));
    }
  }, []);

  const groupActions = [{
    label: 'Create',
    variant: 'contained',
    fn: () => {
      dispatch(
        openModal({
          props: { client, event },
          key: 'createEventSponsorTier'
        })
      )
    }
  }, {
    label: 'Edit',
    requiresSelection: true,
    fn: sponsorTier => {
      dispatch(
        openModal({
          props: { client, event, sponsorTier },
          key: 'editEventSponsorTier'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: sponsorTier => {
      dispatch(
        openDialog({
          props: { client, event, sponsorTier },
          key: 'removeEventSponsorTier'
        })
      )
    }
  }];

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'eventSponsorOptions', client, event }));
    dispatch(updateDrawerContent({
      fetching: eventFetching,
      key: 'eventSponsorOptions',
      options: { client, event } 
    }));
  }, [eventFetching]);

  const reorderSponsorTierList = ({ list }) => {
    dispatch(reorderEventSponsorTiersRequest({ clientURL: client.url, eventURL: event.url, form: { sponsorTiers: list } }));
  }

  const handleSettingsChange = (evt) => {
    dispatch(updateEventSponsorOptionsRequest({ clientURL: client.url, eventURL: event.url, form: evt }));
  }

  const configuration = {
    formElements: {
      rows: [{
        forward: 'Tiers allow sponsors to be grouped into sections for more prominent display styles. Examples include \'Platinum\', \'Gold\', etc.'
      }, {
        elements: [{
          label: 'Sponsors Will Be Grouped Into Tiers',
          name: 'tieredSponsors',
          type: fieldTypes.select,
          options: yesOrNo,
          value: event?.tieredSponsors || false
        }, {
          label: 'Default Sponsor Size',
          name: 'defaultSponsorSize',
          hide: { key: 'tieredSponsors', value: true },
          type: fieldTypes.select,
          options: sponsorSizes,
          value: event?.defaultSponsorSize || 'medium'
        }]
      }, {
        elements: [{
          label: 'Sponsor list location',
          name: 'sponsorPlacement',
          type: fieldTypes.select,
          options: sponsorPlacements,
          value: event?.sponsorPlacement || 'page'
        }]
      }]
    },
    submitCTA: 'Save'
  };
  
  return (
    <section className="sponsors grid">
      <section className="box">
        <div className="box-title">
          <h3>
              {
                !eventFetching && (
                  <span>Event Sponsor Options
                  {
                    updatingSponsorSettings && (
                      <LoadingCircle variant="dark" />
                    )
                  }
                  </span>
                )
              }
              {
                eventFetching && (
                  <LoadingCircle variant="dark" />
                )
              }
            </h3>
        </div>
        {
          !eventFetching && (
          <div className="box-content options">
            <AutoForm
              configuration={configuration}
              handleSubmit={handleSettingsChange}
              requestPrefix={'UPDATE_EVENT_SPONSOR_OPTIONS'}
            />
          </div>
          )
        }
      </section>
      { (!eventFetching && event?.tieredSponsors) &&
        <ResourceList
          actions={groupActions}
          draggable
          fetching={eventFetching}
          handleDrag={reorderSponsorTierList}
          list={event?.sponsorTiers}
          renderItem={sponsorTier =>
            <div className="sponsor-tier">{sponsorTier.name}</div>
          }
          renderKey="sponsorTierID"
          title="Sponsor Tiers"
          updating={updatingSponsorTiers}
        />
      }
    </section>
  )
}

export default EventSponsors;