import {
  changePasswordRequest,
  clearErrorLogsRequest,
  migrateUserRequest,
  removeInvoiceRequest,
  removeInvoiceLineItemRequest,
  removeSystemNotificationRequest
} from "src/redux/actions/app";
import {
  removeAdministratorRequest,
  removeAdministratorInvitationRequest,
  removeLogoRequest,
  revokeSquareRequest,
  revokeStripeRequest
} from "src/redux/actions/client";
import {
  archiveContestRequest,
  removeContestArchiveRequest,
  removeContestAwardRequest,
  removeContestBannerImageRequest,
  removeContestCategoryRequest,
  removeContestCoordinatorRequest,
  removeContestDocumentFolderRequest,
  removeContestDownloadableFileRequest,
  removeContestEntryFormRequest,
  removeContestJudgeRequest,
  removeContestJudgeInvitationRequest,
  removeContestJudgeProspectRequest,
  removeContestOptionalFeeRequest,
  removeContestPromoCodeRequest,
  removeContestRegistrationRequest,
  removeContestScholarshipRequest,
  removeContestScoresheetRequest,
  removeContestSponsorTierRequest,
  removeContestSupportingProductTimeframeRequest,
  removeContestTimeframeRequest,
  requestContestJudgeVolunteerRequest
} from "src/redux/actions/contests";
import {
  removeParadeDateRequest,
  removeParadeDownloadableFileRequest,
  removeParadeHomePageImageRequest,
  removeParadeOptionalFeeRequest,
  removeParadePromoCodeRequest,
  removeParadePropertyGroupRequest,
  removeParadeRegistrationRequest,
  removeParadeSponsorTierRequest,
  removeParadeSupportingProductTimeframeRequest,
  removeParadeTicketRequest,
  removeParadeTicketTakerRequest,
  removeParadeTicketTakerInvitationRequest,
  removeParadeTimeframeRequest
} from "src/redux/actions/parades";
import {
  removePollRequest,
  removePollImageRequest,
  removePollVoteRequest
} from "src/redux/actions/polls";
import {
  archiveEventRequest,
  removeEventBannerImageRequest,
  removeEventCoordinatorRequest,
  removeEventDownloadableFileRequest,
  removeEventOptionalFeeRequest,
  removeEventPromoCodeRequest,
  removeEventRegistrationRequest,
  removeEventSlideshowImageRequest,
  removeEventSponsorTierRequest,
  removeEventSupportingProductTimeframeRequest,
  removeEventTicketRequest,
  removeEventTimeframeRequest
} from "src/redux/actions/events";
import {
  removeHeadshotRequest,
  removeUserMembershipRequest
} from "src/redux/actions/users";

const dialogActions = {
  archiveContest: {
    actionKey: 'ARCHIVE_CONTEST',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest }) => archiveContestRequest({ clientURL: client.url, contestURL: contest.url }),
      label: 'Yes'
    },
    text: 'Archiving an awards program is a reversible action. Performing this action will not delete the program, but will remove it from display on the main page. Do you wish to continue?',
    title: ({ contest }) => `${(contest || {}).archived ? 'Restore' : 'Archive'} ${contest.name}`,
  },
  archiveEvent: {
    actionKey: 'ARCHIVE_EVENT',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event }) => archiveEventRequest({ clientURL: client.url, eventURL: event.url }),
      label: 'Yes'
    },
    text: 'Archiving an event is a reversible action. Performing this action will not delete an event, but will remove it from display on the main page. Do you wish to continue?',
    title: ({ event }) => `${(event || {}).archived ? 'Restore' : 'Archive'} ${event.name}`,
  },
  changePassword: {
    actionKey: 'CHANGE_PASSWORD',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ password, userID }) => changePasswordRequest({ password, userID }),
      label: 'Yes'
    },
    text: 'Changing a user password is an immediate action that cannot be reversed. Do you wish to continue?',
    title: () => 'Migrate User Account',
  },
  clearErrorLogs: {
    actionKey: 'CLEAR_ERROR_LOGS',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: () => clearErrorLogsRequest(),
      label: 'Yes'
    },
    text: 'Clear all error logs?',
    title: () => 'Clear Error Logs',
  },
  migrateUser: {
    actionKey: 'MIGRATE_USER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ newUserID, oldUserID }) => migrateUserRequest({ newUserID, oldUserID }),
      label: 'Yes'
    },
    text: 'Migrating a user account is a permanent action whereas all attributed resources such as awards program entries, submitted scores and memberships will be shifted from one user to the other. Do you wish to continue?',
    title: () => 'Migrate User Account',
  },
  removeAdministrator: {
    actionKey: 'REMOVE_ADMINISTRATOR',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ administrator, client }) => removeAdministratorRequest({ clientURL: client.url, administratorID: administrator.administratorID }),
      label: 'Yes'
    },
    text: 'Remove this administrator?',
    title: ({ administrator }) => `Remove Administrator Rights For ${administrator.fullName}`,
  },
  removeAdministratorInvitation: {
    actionKey: 'REMOVE_ADMINISTRATOR_INVITATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, invitation }) => removeAdministratorInvitationRequest({ clientURL: client.url, invitationKey: invitation.invitationKey }),
      label: 'Yes'
    },
    text: 'Remove this administrator invitation?',
    title: ({ invitation }) => `Remove Administrator Invitation For ${invitation.email}`,
  },
  removeContestArchive: {
    actionKey: 'REMOVE_CONTEST_ARCHIVE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, archive }) => removeContestArchiveRequest({ clientURL: client.url, contestURL: contest.url, form: archive }),
      label: 'Yes'
    },
    text: 'Remove this awards program archive?',
    title: () => `Remove Awards Program Archive`,
  },
  removeContestAward: {
    actionKey: 'REMOVE_CONTEST_AWARD',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, award }) => removeContestAwardRequest({ clientURL: client.url, contestURL: contest.url, form: award }),
      label: 'Yes'
    },
    text: 'Remove this award?',
    title: () => `Remove Awards Program Award`,
  },
  removeContestBannerImage : {
    actionKey: 'REMOVE_CONTEST_BANNER_IMAGE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, bannerImage }) => removeContestBannerImageRequest({ clientURL: client.url, form: { bannerID: bannerImage.bannerID } }),
      label: 'Yes'
    },
    text: 'Remove this banner image?',
    title: () => `Remove Banner Image`,
  },
  removeContestCategory: {
    actionKey: 'REMOVE_CONTEST_CATEGORY',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, category }) => removeContestCategoryRequest({ clientURL: client.url, contestURL: contest.url, form: category }),
      label: 'Yes'
    },
    text: 'Remove this awards program category? Any program entries that exist under this category will no longer be visible.',
    title: () => `Remove Awards Program Category`,
  },
  removeContestCoordinator: {
    actionKey: 'REMOVE_CONTEST_COORDINATOR',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, coordinator }) => removeContestCoordinatorRequest({ clientURL: client.url, contestURL: contest.url, form: coordinator }),
      label: 'Yes'
    },
    text: 'Remove this awards program coordinator?',
    title: () => `Remove Awards Program Coordinator`,
  },
  removeContestDocumentFolder: {
    actionKey: 'REMOVE_CONTEST_DOCUMENT_FOLDER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, documentFolder }) => removeContestDocumentFolderRequest({ clientURL: client.url, contestURL: contest.url, form: documentFolder }),
      label: 'Yes'
    },
    text: 'Remove this awards program document folder?',
    title: () => `Remove Awards Program Document Folder`,
  },
  removeContestDownloadableFile: {
    actionKey: 'REMOVE_CONTEST_DOWNLOADABLE_FILE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, file }) => removeContestDownloadableFileRequest({ clientURL: client.url, contestURL: contest.url, form: file }),
      label: 'Yes'
    },
    text: 'Remove this file?',
    title: () => `Remove Downloadable File`,
  },
  removeContestEntryForm: {
    actionKey: 'REMOVE_CONTEST_ENTRY_FORM',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, entryForm }) => removeContestEntryFormRequest({ clientURL: client.url, contestURL: contest.url, form: entryForm }),
      label: 'Yes'
    },
    text: 'Remove this awards program entry form?',
    title: () => `Remove Awards Program Entry Form`,
  },
  removeContestJudge: {
    actionKey: 'REMOVE_CONTEST_JUDGE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, judge }) => removeContestJudgeRequest({ clientURL: client.url, contestURL: contest.url, form: judge }),
      label: 'Yes'
    },
    text: 'Remove this awards program judging assignment? Any scores previously submitted by this judge will be removed, which can affect scoring averages.',
    title: () => `Remove Awards Program Judge`,
  },
  removeContestJudgeInvitation: {
    actionKey: 'REMOVE_CONTEST_JUDGE_INVITATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, invitation }) => removeContestJudgeInvitationRequest({ clientURL: client.url, contestURL: contest.url, form: invitation }),
      label: 'Yes'
    },
    text: 'Remove this awards program judge invitation?',
    title: ({ invitation }) => `Remove Administrator Invitation For ${invitation.email}`,
  },
  removeContestJudgeProspect: {
    actionKey: 'REMOVE_CONTEST_JUDGE_PROSPECT',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, prospect }) => removeContestJudgeProspectRequest({ clientURL: client.url, contestURL: contest.url, form: prospect }),
      label: 'Yes'
    },
    text: 'Dismiss this awards program judge prospect?',
    title: ({ prospect }) => `Dismiss Administrator Prospect For ${prospect.user.email}`,
  },
  removeContestOptionalFee: {
    actionKey: 'REMOVE_CONTEST_OPTIONAL_FEE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, fee }) => removeContestOptionalFeeRequest({ clientURL: client.url, contestURL: contest.url, form: fee }),
      label: 'Yes'
    },
    text: 'Remove this optional fee?',
    title: () => `Remove Awards Program Optional Fee`,
  },
  removeContestPromoCode: {
    actionKey: 'REMOVE_CONTEST_PROMO_CODE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, promoCode }) => removeContestPromoCodeRequest({ clientURL: client.url, contestURL: contest.url, form: promoCode }),
      label: 'Yes'
    },
    text: 'Remove this promotional code?',
    title: () => `Remove Awards Program Promo Code`,
  },
  removeContestRegistration: {
    actionKey: 'REMOVE_CONTEST_REGISTRATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, registration }) => removeContestRegistrationRequest({ clientURL: client.url, contestURL: contest.url, form: registration }),
      label: 'Yes'
    },
    text: 'Remove this awards program registration?',
    title: () => `Remove Awards Program Registration`,
  },
  removeContestScholarship: {
    actionKey: 'REMOVE_CONTEST_SCHOLARSHIP',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, scholarship }) => removeContestScholarshipRequest({ clientURL: client.url, contestURL: contest.url, form: scholarship }),
      label: 'Yes'
    },
    text: 'Remove this awards program scholarship? Any applications that exist under this scholarship will be removed.',
    title: () => `Remove Awards Program Scholarship`,
  },
  removeContestScoresheet: {
    actionKey: 'REMOVE_CONTEST_SCORESHEET',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, scoresheet }) => removeContestScoresheetRequest({ clientURL: client.url, contestURL: contest.url, form: scoresheet }),
      label: 'Yes'
    },
    text: 'Remove this awards program scoresheet? Any scores previously recorded for this sheet will be discarded.',
    title: () => `Remove Awards Program Scoresheet`,
  },
  removeContestScoresheetCriteria: {
    cancel: {
      label: 'No'
    },
    confirm: {
      fn: ({ fn }) => fn(),
      label: 'Yes'
    },
    text: 'Remove this scoresheet criteria?',
    title: () => `Remove Scoresheet Criteria`,
  },
  removeContestSponsorTier: {
    actionKey: 'REMOVE_CONTEST_SPONSOR_TIER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, sponsorTier }) => removeContestSponsorTierRequest({ clientURL: client.url, contestURL: contest.url, form: sponsorTier }),
      label: 'Yes'
    },
    text: 'Remove this awards program sponsor tier?',
    title: () => `Remove Awards Program Sponsor Tier`,
  },
  removeContestSponsorTimeframe: {
    actionKey: 'REMOVE_CONTEST_SUPPORTING_PRODUCT_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, timeframe }) => removeContestSupportingProductTimeframeRequest({ clientURL: client.url, contestURL: contest.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this sponsor pricing timeframe?',
    title: () => `Remove Awards Program Sponsor Pricing Timeframe`,
  },
  removeContestTimeframe: {
    actionKey: 'REMOVE_CONTEST_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, timeframe }) => removeContestTimeframeRequest({ clientURL: client.url, contestURL: contest.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this pricing timeframe?',
    title: () => `Remove Awards Program Pricing Timeframe`,
  },
  requestContestVolunteer: {
    actionKey: 'REQUEST_CONTEST_JUDGE_VOLUNTEER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, contest, volunteer }) => requestContestJudgeVolunteerRequest({ clientURL: client.url, contestURL: contest.url, form: volunteer }),
      label: 'Yes'
    },
    text: `This action will send this user an email asking them if they would like to judge your awards program. You and all program administrators will be copied on this email. 
    This person will also be added to your judge prospects list. Do you wish to proceed?`,
    title: () => `Request Awards Program Judge Volunteer`,
  },
  removeEventBannerImage : {
    actionKey: 'REMOVE_EVENT_BANNER_IMAGE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, bannerImage }) => removeEventBannerImageRequest({ clientURL: client.url, form: { bannerID: bannerImage.bannerID } }),
      label: 'Yes'
    },
    text: 'Remove this banner image?',
    title: () => `Remove Banner Image`,
  },
  removeEventCoordinator: {
    actionKey: 'REMOVE_EVENT_COORDINATOR',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, coordinator }) => removeEventCoordinatorRequest({ clientURL: client.url, eventURL: event.url, form: coordinator }),
      label: 'Yes'
    },
    text: 'Remove this event coordinator?',
    title: () => `Remove Event Coordinator`,
  },
  removeEventDownloadableFile: {
    actionKey: 'REMOVE_EVENT_DOWNLOADABLE_FILE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, file }) => removeEventDownloadableFileRequest({ clientURL: client.url, eventURL: event.url, form: file }),
      label: 'Yes'
    },
    text: 'Remove this file?',
    title: () => `Remove Downloadable File`,
  },
  removeEventOptionalFee: {
    actionKey: 'REMOVE_EVENT_OPTIONAL_FEE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, fee }) => removeEventOptionalFeeRequest({ clientURL: client.url, eventURL: event.url, form: fee }),
      label: 'Yes'
    },
    text: 'Remove this optional fee?',
    title: () => `Remove Event Optional Fee`,
  },
  removeEventPromoCode: {
    actionKey: 'REMOVE_EVENT_PROMO_CODE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, promoCode }) => removeEventPromoCodeRequest({ clientURL: client.url, eventURL: event.url, form: promoCode }),
      label: 'Yes'
    },
    text: 'Remove this promotional code?',
    title: () => `Remove Event Promo Code`,
  },
  removeEventRegistration: {
    actionKey: 'REMOVE_EVENT_REGISTRATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, registration }) => removeEventRegistrationRequest({ clientURL: client.url, eventURL: event.url, form: registration }),
      label: 'Yes'
    },
    text: 'Remove this event registration?',
    title: () => `Remove Event Registration`,
  },
  removeEventSlideshowImage: {
    actionKey: 'REMOVE_EVENT_SLIDESHOW_IMAGE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, slideshowImage }) => removeEventSlideshowImageRequest({ clientURL: client.url, eventURL: event.url, form: { slideshowImageID: slideshowImage.slideshowImageID } }),
      label: 'Yes'
    },
    text: 'Remove this image?',
    title: () => `Remove Slideshow Image`,
  },
  removeEventSponsorTier: {
    actionKey: 'REMOVE_EVENT_SPONSOR_TIER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, sponsorTier }) => removeEventSponsorTierRequest({ clientURL: client.url, eventURL: event.url, form: sponsorTier }),
      label: 'Yes'
    },
    text: 'Remove this event sponsor tier?',
    title: () => `Remove Event Sponsor Tier`,
  },
  removeEventSponsorTimeframe: {
    actionKey: 'REMOVE_EVENT_SUPPORTING_PRODUCT_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, timeframe }) => removeEventSupportingProductTimeframeRequest({ clientURL: client.url, eventURL: event.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this sponsor pricing timeframe?',
    title: () => `Remove Event Sponsor Pricing Timeframe`,
  },
  removeEventTicket: {
    actionKey: 'REMOVE_EVENT_TICKET',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, ticket }) => removeEventTicketRequest({ clientURL: client.url, eventURL: event.url, form: ticket }),
      label: 'Yes'
    },
    text: 'Remove this ticket?',
    title: () => `Remove Event Ticket`,
  },
  removeEventTimeframe: {
    actionKey: 'REMOVE_EVENT_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, event, timeframe }) => removeEventTimeframeRequest({ clientURL: client.url, eventURL: event.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this pricing timeframe?',
    title: () => `Remove Event Pricing Timeframe`,
  },
  removeFormOption: {
    cancel: {
      label: 'No'
    },
    confirm: {
      fn: ({ fn }) => fn(),
      label: 'Yes'
    },
    text: 'Remove this option?',
    title: () => `Remove Option`,
  },
  removeFormQuestion: {
    cancel: {
      label: 'No'
    },
    confirm: {
      fn: ({ fn }) => fn(),
      label: 'Yes'
    },
    text: 'Remove this question?',
    title: () => `Remove Question`,
  },
  removeHeadshot: {
    actionKey: 'REMOVE_HEADSHOT',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: () => removeHeadshotRequest(),
      label: 'Yes'
    },
    text: 'Remove your headshot?',
    title: () => 'Remove User Profile Headshot',
  },
  removeInvoice: {
    actionKey: 'REMOVE_INVOICE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ clientURL, invoice }) => removeInvoiceRequest({ clientURL, form: invoice }),
      label: 'Yes'
    },
    text: 'Remove this invoice/receipt?',
    title: () => 'Remove Invoice/Receipt',
  },
  removeInvoiceLineItem: {
    actionKey: 'REMOVE_INVOICE_LINE_ITEM',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ itemID }) => removeInvoiceLineItemRequest({ itemID }),
      label: 'Yes'
    },
    text: 'Remove this invoice line item?',
    title: () => 'Remove Invoice Line Item'
  },
  removeLogo: {
    actionKey: 'REMOVE_LOGO',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client }) => removeLogoRequest({ clientURL: client.url }),
      label: 'Yes'
    },
    text: 'Remove the logo?',
    title: () => 'Remove Logo',
  },
  removeParadeDate: {
    actionKey: 'REMOVE_PARADE_DATE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, date }) => removeParadeDateRequest({ clientURL: client.url, paradeURL: parade.url, form: date }),
      label: 'Yes'
    },
    text: 'Remove this parade date / time?.',
    title: () => `Remove Parade Date / Time`,
  },
  removeParadeDownloadableFile: {
    actionKey: 'REMOVE_PARADE_DOWNLOADABLE_FILE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, file }) => removeParadeDownloadableFileRequest({ clientURL: client.url, paradeURL: parade.url, form: file }),
      label: 'Yes'
    },
    text: 'Remove this file?',
    title: () => `Remove Downloadable File`,
  },
  removeParadeHomePageColumn: {
    cancel: {
      label: 'No'
    },
    confirm: {
      fn: ({ fn }) => fn(),
      label: 'Yes'
    },
    text: 'Remove this home page column?',
    title: () => `Remove Home Page Column`,
  },
  removeParadeHomePageImage: {
    actionKey: 'REMOVE_PARADE_HOME_PAGE_IMAGE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, file }) => removeParadeHomePageImageRequest({ clientURL: client.url, paradeURL: parade.url, form: file }),
      label: 'Yes'
    },
    text: 'Remove this parade website home page image?',
    title: () => `Remove Parade Website Home Page Image`,
  },
  removeParadeHomePageRow: {
    cancel: {
      label: 'No'
    },
    confirm: {
      fn: ({ fn }) => fn(),
      label: 'Yes'
    },
    text: 'Remove this home page row?',
    title: () => `Remove Home Page Row`,
  },
  removeParadeOptionalFee: {
    actionKey: 'REMOVE_PARADE_OPTIONAL_FEE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, fee }) => removeParadeOptionalFeeRequest({ clientURL: client.url, paradeURL: parade.url, form: fee }),
      label: 'Yes'
    },
    text: 'Remove this optional fee?',
    title: () => `Remove Event Optional Fee`,
  },
  removeParadePromoCode: {
    actionKey: 'REMOVE_PARADE_PROMO_CODE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, promoCode }) => removeParadePromoCodeRequest({ clientURL: client.url, paradeURL: parade.url, form: promoCode }),
      label: 'Yes'
    },
    text: 'Remove this promotional code?',
    title: () => `Remove Parade Promo Code`,
  },
  removeParadePropertyGroup: {
    actionKey: 'REMOVE_PARADE_PROPERTY_GROUP',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, propertyGroup }) => removeParadePropertyGroupRequest({ clientURL: client.url, paradeURL: parade.url, form: propertyGroup }),
      label: 'Yes'
    },
    text: 'Remove this parade property group? Any properties that exist under this group may no longer be visible.',
    title: () => `Remove Parade Property Group`,
  },
  removeParadePropertyTimeframe: {
    actionKey: 'REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, timeframe }) => removeParadeSupportingProductTimeframeRequest({ clientURL: client.url, paradeURL: parade.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this property pricing timeframe?',
    title: () => `Remove Parade Property Pricing Timeframe`,
  },
  removeParadeRegistration: {
    actionKey: 'REMOVE_PARADE_REGISTRATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, registration }) => removeParadeRegistrationRequest({ clientURL: client.url, paradeURL: parade.url, form: registration }),
      label: 'Yes'
    },
    text: 'Remove this parade registration?',
    title: () => `Remove Parade Registration`,
  },
  removeParadeSponsorTier: {
    actionKey: 'REMOVE_PARADE_SPONSOR_TIER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, sponsorTier }) => removeParadeSponsorTierRequest({ clientURL: client.url, paradeURL: parade.url, form: sponsorTier }),
      label: 'Yes'
    },
    text: 'Remove this parade website sponsor tier?',
    title: () => `Remove Parade Website Sponsor Tier`,
  },
  removeParadeSponsorTimeframe: {
    actionKey: 'REMOVE_PARADE_SUPPORTING_PRODUCT_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, timeframe }) => removeParadeSupportingProductTimeframeRequest({ clientURL: client.url, paradeURL: parade.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this sponsor pricing timeframe?',
    title: () => `Remove Parade Sponsor Pricing Timeframe`,
  },
  removeParadeTicket: {
    actionKey: 'REMOVE_PARADE_TICKET',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, ticket }) => removeParadeTicketRequest({ clientURL: client.url, paradeURL: parade.url, form: ticket }),
      label: 'Yes'
    },
    text: 'Remove this ticket?',
    title: () => `Remove Parade Website Ticket`,
  },
  removeParadeTicketTaker: {
    actionKey: 'REMOVE_PARADE_TICKET_TAKER',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, ticketTaker }) => removeParadeTicketTakerRequest({ clientURL: client.url, paradeURL: parade.url, form: ticketTaker }),
      label: 'Yes'
    },
    text: 'Remove this ticket taker?',
    title: () => `Remove Parade Website Ticket Taker`,
  },
  removeParadeTicketTakerInvitation: {
    actionKey: 'REMOVE_PARADE_TICKET_TAKER_INVITATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, invitation }) => removeParadeTicketTakerInvitationRequest({ clientURL: client.url, paradeURL: parade.url, form: invitation }),
      label: 'Yes'
    },
    text: 'Remove this ticket taker invitation?',
    title: ({ invitation }) => `Remove Ticket Taker Invitation For ${invitation.email}`,
  },
  removeParadeTimeframe: {
    actionKey: 'REMOVE_PARADE_TIMEFRAME',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, parade, timeframe }) => removeParadeTimeframeRequest({ clientURL: client.url, paradeURL: parade.url, form: timeframe }),
      label: 'Yes'
    },
    text: 'Remove this pricing timeframe?',
    title: () => `Remove Parade Website Pricing Timeframe`,
  },
  removePoll: {
    actionKey: 'REMOVE_POLL',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, poll }) => removePollRequest({ clientURL: client.url, pollURL: poll.url }),
      label: 'Yes'
    },
    text: 'Remove this survey? This is a permanent action and cannot be reversed.',
    title: () => `Remove Survey`,
  },
  removePollImage: {
    actionKey: 'REMOVE_POLL_IMAGE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, poll, file }) => removePollImageRequest({ clientURL: client.url, pollURL: poll.url, form: file }),
      label: 'Yes'
    },
    text: 'Remove this survey image?.',
    title: () => `Remove Survey`,
  },
  removePollVoter: {
    actionKey: 'REMOVE_POLL_VOTE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client, poll, voter }) => removePollVoteRequest({ clientURL: client.url, pollURL: poll.url, form: voter }),
      label: 'Yes'
    },
    text: 'Remove this vote? This voter will be able to resubmit their vote after this action.',
    title: () => `Remove Vote`,
  },
  removeRegistrationItem: {
    cancel: {
      label: 'No'
    },
    confirm: {
      fn: ({ fn }) => fn(),
      label: 'Yes'
    },
    text: 'Remove this registration item?',
    title: () => `Remove Registration Item`,
  },
  revokeSquare: {
    actionKey: 'REVOKE_SQUARE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client }) => revokeSquareRequest({ clientURL: client.url }),
      label: 'Yes'
    },
    text: 'Remove access to Square? Re-enabling Square access will require authorization with the primary Square account.',
    title: () => 'Revoke Square Access',
  },
  revokeStripe: {
    actionKey: 'REVOKE_STRIPE',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ client }) => revokeStripeRequest({ clientURL: client.url }),
      label: 'Yes'
    },
    text: 'Remove access to Stripe? Re-enabling Stripe access will require authorization with the primary Stripe account.',
    title: () => 'Revoke Stripe Access',
  },
  removeSystemNotification: {
    actionKey: 'REMOVE_SYSTEM_NOTIFICATION',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ notification }) => removeSystemNotificationRequest({ form: { notificationID: notification.notificationID } }),
      label: 'Yes'
    },
    text: 'Remove this system notification?',
    title: () => 'Remove System Notification',
  },
  removeMembership: {
    actionKey: 'REMOVE_USER_MEMBERSHIP',
    cancel: {
      label: 'No'
    },
    confirm: {
      action: ({ membership }) => removeUserMembershipRequest({ form: membership }),
      label: 'Yes'
    },
    text: 'Remove this membership?',
    title: () => 'Remove User Membership',
  },
}

export default dialogActions;
