import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParadeRequest } from 'src/redux/actions/parades';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingCircle from 'src/components/Elements/LoadingCircle';
import AppCard from 'src/components/Elements/AppCard';

const Parade = () => {
  const client = useSelector(state => state.clientStore?.client);
  const parade = useSelector(state => state.paradeStore?.parade);
  const paradeFetching = useSelector(state => state.loadingStore.GET_PARADE);
  const { paradeURL } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!paradeURL) {
    navigate('/');
  }

  useEffect(() => {
    dispatch(getParadeRequest({ clientURL: client.url, paradeURL }));
  }, []);

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'parade', client, parade }));
    dispatch(updateDrawerContent({
      fetching: paradeFetching,
      key: 'parade',
      options: { client, parade } 
    }));
  }, [parade?.name, parade?.url, paradeFetching]);

  return (
    <section className="app-grid">
      {
        paradeFetching && (
          <div className="app-grid-loading">
              <LoadingCircle variant="dark" />
          </div>
        )
      }
      {
        !paradeFetching && (
          <>
            <div className="app-grid-row">
              <AppCard
                className="flex-1"
                title="Online Parade Portal"
              >
                <div className="app-grid-title">
                  <div className="app-grid-title-details">
                    <h1>
                      {parade?.name}
                    </h1>
                  </div>
                </div>
              </AppCard>
              <div className="card-group">
                <AppCard
                  className="shrinkable"
                  title="Landing Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client.url}.cpjam.com/${parade?.url}`}
                  >
                    {`https://${client.url}.cpjam.com/${parade?.url}`}
                  </Link>
                </AppCard>
                <AppCard
                  className="shrinkable"
                  title="Registration Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client.url}.cpjam.com/${parade?.url}/tickets`}
                  >
                    {`https://${client.url}.cpjam.com/${parade?.url}/tickets`}
                  </Link>
                </AppCard>
                <AppCard
                  className="shrinkable"
                  title="Become A Partner Page"
                >
                  <Link
                    className="item"
                    target="_blank"
                    to={`https://${client?.url}.cpjam.com/${parade?.url}/become-a-partner`}
                  >
                    {`https://${client?.url}.cpjam.com/${parade?.url}/become-a-partner`}
                  </Link>
                </AppCard>
              </div>
            </div>
          </>
        )
      }
    </section>
  )
}

export default Parade;
