import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import { getInvoicesRequest } from 'src/redux/actions/app';
import { updateDrawerContent } from 'src/redux/actions/drawer';
import { currency } from 'src/utilities/strings';
import ResourceList from 'src/components/ResourceList';
import { openModal } from 'src/redux/actions/modal';
import { openDialog } from 'src/redux/actions/dialog';

const Invoices = () => {
  const [filters, setFilters] = useState();
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [currentStatus, setCurrentStatus] = useState('unpaid');
  const [totalPendingRevenue, setTotalPendingRevenue] = useState(0);
  const invoices = useSelector(state => state.appStore?.invoices);
  const invoiceItems = useSelector(state => state.appStore?.invoiceItems);
  const invoicesFetching = useSelector(state => state.loadingStore.GET_INVOICES);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'invoices' }));
    dispatch(updateDrawerContent({ key: 'networkInvoices' }));

    if (!invoicesFetching) {
      dispatch(getInvoicesRequest());
    }
  }, []);

  useEffect(() => {
    if (invoices) {
      const year = new Date().getFullYear();
      const years = {};
      for (let i = year; i > 2019; i--) {
        years[i] = {
          fn: item => item.invoiceYear === i,
          label: i
        };
      }

      setFilters({
        default: year,
        secondaryDefault: 'unpaid',
        options: years,
        noAll: true,
        secondaryOptions: {
          unpaid: {
            fn: item => !item.paidDate,
            label: 'Unpaid'
          },
          paid: {
            fn: item => !!item.paidDate,
            label: 'Paid'
          }
        }
      })
      setTotalRevenue(invoices.filter(invoice => {
        return invoice.invoiceYear === year && !invoice.paidDate
      }).reduce((acc, invoice) => acc + invoice.amount, 0));

      setTotalPendingRevenue(invoiceItems.reduce((acc, invoice) => acc + invoice.amount, 0));
    }
  }, [invoices]);

  const handleFilterChange = ({ list, selectedSecondaryFilterOption }) => {
    setTotalRevenue(list.reduce((acc, invoice) => acc + invoice.amount, 0));
    setCurrentStatus(selectedSecondaryFilterOption);
  }

  const lineItemActions = [{
    label: 'Create',
    variant: 'contained',
    requiresSelection: true,
    fn: invoiceLineItem => {
      const invoice = invoices.find(invoice => invoice.clientURL === invoiceLineItem.clientURL && invoice.paidBy === 'Unpaid');
      const items = [...(((invoice || {}).items || [])), ...invoiceItems.filter(item => item.clientURL === invoiceLineItem.clientURL)];
      dispatch(
        openModal({
          props: { clientURL: invoiceLineItem.clientURL, invoice, items },
          key: 'editInvoice'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: invoiceLineItem => {
      dispatch(
        openDialog({
          props: { itemID: invoiceLineItem.itemID },
          key: 'removeInvoiceLineItem'
        })
      )
    }
  }];

  const invoiceActions = [{
    disabled: (invoice) => !!invoice.paidDate,
    label: 'Edit',
    variant: 'contained',
    requiresSelection: true,
    fn: invoice => {
      const items = [...(((invoice || {}).items || [])), ...invoiceItems.filter(item => item.clientURL === invoice.clientURL)];
      dispatch(
        openModal({
          props: { clientURL: invoice.clientURL, invoice, items },
          key: 'editInvoice'
        })
      )
    }
  }, {
    color: 'danger',
    label: 'Remove',
    requiresSelection: true,
    variant: 'contained',
    fn: invoice => {
      dispatch(
        openDialog({
          props: { clientURL: invoice.clientURL, invoice },
          key: 'removeInvoice'
        })
      )
    }
  }];

  return (
    <section className="invoices grid">
        <ResourceList
          actions={lineItemActions}
          fetching={invoicesFetching}
          list={invoiceItems}
          renderItem={invoice => 
            <div className="receipt">
              <div className="receipt-client">
                {invoice.clientName}
              </div>
              <div className="receipt-total">
                <span>
                  {invoice.name}
                </span>
                <span className="emphasize">{currency(invoice.amount)}</span>
              </div>
            </div>
          }
          renderKey="itemID"
          title={`Outstanding Line Items: ${currency(totalPendingRevenue)} Pending`}
        />
        <ResourceList
          actions={invoiceActions}
          fetching={invoicesFetching}
          filters={filters}
          list={invoices}
          onFilterChange={handleFilterChange}
          renderItem={invoice => 
            <div className="receipt">
              <div className="receipt-client">
                {invoice.clientName}
              </div>
              <div className="receipt-items">
                {invoice.items.map(item => 
                  <div key={item.itemID} className="receipt-items-item">
                    <span>{item.name}</span>
                    <span>{currency(item.amount)}</span>
                  </div>
                )}
              </div>
              <div className="receipt-total">
                <span>
                  {
                    invoice.paidDate && <>Paid On: <span className="emphasize">{invoice.paidDate}</span></>
                  }
                  {
                    !invoice.paidDate && <>Total:</>
                  }
                </span>
                <span className="emphasize">{currency(invoice.amount)}</span>
              </div>
            </div>
          }
          renderKey="invoiceID"
          title={`
            ${currentStatus === 'paid' ? 'Paid Invoices ' : 'Outstanding Invoices '}
            ${currency(totalRevenue)}
            ${currentStatus === 'paid' ? ' Received' : ' Pending'}
          `}
        />
    </section>
  )
}

export default Invoices;
