import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField, debounce } from '@mui/material';
import LoadingCircle from '../Elements/LoadingCircle';
import { getSearchListRequest } from 'src/redux/actions/search';
import UserProfile from 'src/components/UserProfile';

const Typeahead = ({
  onSelect,
  permitInvitation,
  permitTypedValue,
  placeholder,
  selecting
}) => {
  const results = useSelector(state => state.searchStore?.results);
  const sendInvite = useSelector(state => state.searchStore?.sendInvite);
  const typedValue = useSelector(state => state.searchStore?.typedValue);
  const recordsFetching = useSelector(state => state.loadingStore.GET_SEARCH_LIST);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] =  useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (sendInvite) {
      setOptions([...(results || []), {
        fullName: 'Send Invitation',
        sendInvite
      }]);

      return;
    }

    if (typedValue) {
      setOptions([...(results || []), {
        fullName: 'Select Typed Value',
        typedValue
      }]);

      return;
    }

    setOptions(results);
  }, [results]);

  useEffect(() => {
    setOptions([]);
    return () => {
      setOptions([]);
    }
  }, [])

  const debouncedFn = (search) => {
    if (search?.length > 2) {
      dispatch(getSearchListRequest({ permitInvitation, permitTypedValue, search }));
      return;
    }
    setOptions([]);
  }

  const searchFn = useCallback(
    debounce(value => debouncedFn(value), 300)
  , []);

  const handleChange = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return;
    }

    setSearch(event.target.value);
    searchFn(event.target.value);
  };

  const handleSelect = (event) => {
    onSelect(event);
    setOpen(false);
    setOptions([]);
    setSearch('');
  }

  return (
    <Autocomplete
      disableClearable={true}
      freeSolo
      id="user-search"
      className="user-search"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={search}
      onInputChange={handleChange}
      filterOptions={option => option}
      options={options || []}
      getOptionLabel={option => option.fullName || ''}
      renderOption={(props, option) => {
        return <li onClick={() => handleSelect(option)} key={option.fullName}>
          <UserProfile user={option} showEmail></UserProfile>
        </li>
      }}
      loading={selecting || recordsFetching}
      renderInput={params => {
        return (
          <TextField
            {...params}
            placeholder={placeholder || "Search..."}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {(recordsFetching) ? <LoadingCircle variant="dark" /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />
  );
};

export default Typeahead;
